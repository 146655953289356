.pagination {
  display: inline-block;
}
.pagination > li {
  display: inline;
}
.pagination > li > a {
  position: relative;
  float: left;
  padding: 5px 12px 5px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: white;
  background-color: #477857;
  border: 1px solid #477857;
  margin: 2px;
  border-radius: 2px;
}

.pagination > .active > a {
  color: black;
  background-color: white;
  border: 2px solid #477857;
  padding: 4px 11px 4px 11px;
  margin: 2px;
  border-radius: 2px;
}

.tmp {
  color: red;
}
